import { createTheme, alpha } from '@itsa.io/ui';
import defaultTheme from './default-theme';
import colors from './colors';

export default createTheme({
	...defaultTheme,
	homeImage: 'url("/images/front-photo-dark.webp")',
	homeImageY: -40,
	palette: {
		...colors,
		type: 'dark',
		// background: colors.grey[800],
		background: colors.grey[500],
		backgroundExtreme: colors.black,
		text: {
			primary: colors.grey[50],
			secondary: colors.grey[200],
		},
		primary: {
			light: colors.white,
			main: colors.grey[100],
			dark: colors.grey[800],
			contrastText: colors.white,
		},
		secondary: {
			light: colors.grey[50],
			main: colors.grey[500],
			dark: colors.grey[600],
			contrastText: colors.white,
		},
		// error: {
		// 	light: colors.red[400],
		// 	main: colors.red[600],
		// 	dark: colors.red[800],
		// 	contrastText: colors.white,
		// },
		// warning: {
		// 	light: colors.orange[300],
		// 	main: colors.orange[500],
		// 	dark: colors.orange[700],
		// 	contrastText: colors.white,
		// },
		// // Use defaults for info
		// // info: {},
		// success: {
		// 	light: colors.green[100],
		// 	main: colors.green[300],
		// 	dark: colors.green[500],
		// 	contrastText: colors.white,
		// },
		default: {
			light: alpha(colors.grey[400], 0.3),
			main: colors.grey[400],
			dark: colors.grey[500],
			contrastText: colors.white,
		},
	},
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: `${alpha(colors.black, 0.93)}!important`,
			},
		},
		MuiDrawer: {
			root: {
				// backgroundColor: colors.grey[400],
			},
			paper: {
				backgroundColor: colors.grey[400],
			},
		},
		MuiTabs: {
			root: {
				borderTop: `1px solid ${colors.grey[800]}`,
				backgroundColor: colors.grey[700],
			},
		},
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: colors.grey[500],
			},
		},
		MuiListItem: {
			root: {
				'&:hover': {
					backgroundColor: `${alpha(colors.grey[500], 0.85)}!important`,
				},
				'&$selected': {
					backgroundColor: alpha(colors.grey[500], 0.85),
				},
			},
		},
		MuiListItemText: {
			root: {
				color: colors.grey[50],
			},
		},
		MuiPickersBasePicker: {
			pickerView: {
				backgroundColor: colors.black,
			},
		},
		MuiButton: {
			outlined: {
				borderRadius: 20,
				borderColor: colors.grey[100],
				borderWidth: 2,
			},
			/*
			containedPrimary: {
				color: colors.grey[500],
				backgroundColor: `${colors.white}!important`,
			},
			containedSecondary: {
				backgroundColor: `${colors.grey[500]}!important`,
				border: `1px solid ${alpha(colors.grey[200], 0.2)}`,
			},
			*/
			sizeLarge: {
				fontSize: 16,
				fontWeight: 600,
			},
			label: {
				textTransform: 'none',
			},
		},
		MuiIconButton: {
			root: {
				borderRadius: 6,
				'& .MuiTouchRipple-root *': {
					borderRadius: 6,
				},
			},
		},
		MuiSvgIcon: {
			root: {
				color: colors.grey[50],
			},
		},
		MuiSelect: {
			outlined: {
				padding: '11px 14px',
			},
		},
		MuiMenu: {
			list: {
				background: colors.grey[400],
				borderRadius: 6,
			},
		},
		MuiMenuItem: {
			root: {
				'&$selected, &$selected:hover': {
					backgroundColor: alpha(colors.grey[500], 0.85),
				},
			},
		},
		MuiTextField: {
			root: {
				'& .MuiInputLabel-root': {
					fontSize: 16,
					fontWeight: 600,
				},
			},
		},
		MuiFilledInput: {
			root: {
				'& .MuiFilledInput-input': {
					fontSize: 28,
					fontWeight: 600,
					color: colors.grey[50],
				},
				'&.MuiFilledInput-root, &.MuiFilledInput-root:hover': {
					color: colors.grey[100],
					border: `1px solid ${alpha(colors.grey[400], 0.8)}`,
					backgroundColor: alpha(colors.grey[400], 0.5),
					borderRadius: 6,
				},
				'&$focused': {
					'&.Mui-focused': {
						backgroundColor: alpha(colors.grey[400], 0.5),
					},
				},
				'& .MuiInputAdornment-filled': {
					display: 'block',
					height: 'auto',
					maxHeight: 'none',
				},
			},
		},
		MuiFormHelperText: {
			root: {
				color: colors.grey[200],
				fontWeight: 600,
			},
		},
		MuiTableContainer: {
			root: {
				borderRadius: 12,
				border: `1px solid ${alpha(colors.grey[400], 0.5)}`,
				backgroundColor: alpha(colors.grey[400], 0.3),
			},
		},
		MuiTableCell: {
			root: {
				borderBottom: `1px solid ${alpha(colors.grey[400], 0.5)}`,
			},
			head: {
				fontSize: 12,
				fontWeight: 700,
				color: colors.grey[100],
				textTransform: 'uppercase',
				height: 65,
			},
			body: {
				color: colors.grey[200],
				borderBottom: `1px solid ${alpha(colors.grey[400], 0.5)}`,
			},
		},
		MuiAlert: {
			filledWarning: {
				backgroundColor: colors.orange[400],
			},
			filledInfo: {
				backgroundColor: colors.blue[400],
			},
			filledSuccess: {
				backgroundColor: colors.green[400],
			},
			filledError: {
				backgroundColor: colors.red[400],
			},
		},
		/*
		MuiOutlinedInput: {
			input: {
				'&:-webkit-autofill': {
					WebkitBoxShadow: `0 0 0 100px ${colors.grey[800]} inset`,
					WebkitTextFillColor: colors.white,
				},
			},
		},
		MuiFilledInput: {
			input: {
				'&:-webkit-autofill': {
					WebkitBoxShadow: `0 0 0 100px ${colors.grey[100]} inset`,
					WebkitTextFillColor: colors.grey[900],
				},
			},
		}, */
	},
});

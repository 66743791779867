import { capitalize, lowercase } from 'lodash';
import { useIntl } from '@itsa.io/web3utils';

/**
 * Gets page title and description from translation
 * based on fixed page name.
 *
 * @returns {object}
 */

const HOMEPAGE = 'index';

const usePageMeta = props => {
	const { t } = useIntl();
	const page = props.uri.split('/')[1].replaceAll('_', '');
	const appName = t('common.appname');
	const pageName = lowercase(page).toLowerCase() || HOMEPAGE;
	const pageTranslationName = pageName.replace(/\s/g, '') || HOMEPAGE;

	const pageTitle = t(`page.${pageTranslationName}.page_title`, {
		def: capitalize(pageName) || appName,
	});

	const pageDescription = t(`page.${pageName}.heading`, {
		def: appName,
	});

	return {
		pageTitle,
		pageDescription,
	};
};

export default usePageMeta;

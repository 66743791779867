import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { getWeb3ws } from 'utils/get-web3';
import getBlockHeight from 'utils/get-blockheight';
import { cryptowalletCtx } from '@itsa.io/web3utils';
import { NETWORK_NAMES } from 'config';

const EMPTY_STATE = {
	blockheight: '',
	updated: null,
};

const useBlockHeight = () => {
	const subscription = useRef();
	const { chainId } = useContext(cryptowalletCtx);
	const chainIdRef = useRef(chainId);
	const [blockHeight, setBlockHeight] = useState(EMPTY_STATE);

	const subscribe = async () => {
		try {
			if (chainIdRef.current) {
				const web3 = getWeb3ws(chainIdRef.current);
				subscription.current = web3.eth.subscribe(
					'newBlockHeaders',
					(error, result) => {
						if (!error) {
							const newBlockHeight = {
								blockheight: result.number,
								updated: new Date(),
							};
							setBlockHeight(newBlockHeight);
						}
					},
				);
				// one time initially set blockheight:
				const blockheight = await getBlockHeight(chainIdRef.current);
				const newBlockHeight = {
					blockheight,
					updated: new Date(),
				};
				setBlockHeight(newBlockHeight);
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	const unsubscribe = () => {
		if (subscription.current) {
			subscription.current.unsubscribe();
			subscription.current = null;
		}
	};

	useEffect(() => {
		subscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return unsubscribe;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		unsubscribe();
		chainIdRef.current = chainId;
		if (NETWORK_NAMES[chainId]) {
			subscribe();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId]);

	return blockHeight;
};

const store = createStore(useBlockHeight);

export const { Provider } = store;
export default store.ctx;

import React from 'react';
import useStyles from 'styles/components/common/WalletConnect';
import { useIntl } from '@itsa.io/web3utils';
import { WalletConnect as WalletConnectComp } from '@itsa.io/ui';

const WalletConnect = () => {
	const classes = useStyles();
	const { t } = useIntl();

	const walletConnect = (
		<WalletConnectComp
			classes={{
				root: classes.metamaskRoot,
				title: classes.metamaskTitle,
				titleNetwork: classes.metamaskTitleNetwork,
				description: classes.metamaskDescription,
				networkIconConnected: classes.metamaskIcon,
				addressDescription: classes.metamaskAddressDescription,
				iconBox: classes.metamaskIconBox,
				iconBoxInner: classes.metamaskIconBoxInner,
				select: classes.metamaskSelect,
				selectRoot: classes.metamaskSelectRoot,
				walletMainImage: classes.walletMainImage,
			}}
			labelTitle={t('walletconnect.title')}
			labelGetStarted={t('walletconnect.get_started')}
		/>
	);

	return <div className={classes.root}>{walletConnect}</div>;
};
export default WalletConnect;

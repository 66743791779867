import { useState } from 'react';
import createStore from 'ctx-provider';
import { localStorageProperty } from '@itsa.io/web3utils';
import { SOUNDS } from 'config';

const localStorageSound = localStorageProperty('sound', {
	simpleType: true,
	encoded: false,
});

const useStore = () => {
	const [sound, setSoundState] = useState(localStorageSound.get() || SOUNDS[0]);

	const setSound = listitem => {
		if (typeof listitem === 'string') {
			if (SOUNDS.includes(listitem)) {
				setSoundState(listitem);
				localStorageSound.set(listitem);
			}
		}
	};

	return {
		sound,
		setSound,
	};
};

const store = createStore(useStore);

export const { Provider } = store;
export default store.ctx;

const privatePages = {
	'/my-properties': true,
	'/my-properties-details': true,
	'/tokenize-property': true,
	'/tokenize-property-details': true,
	'/fund-a-home': true,
	'/close-the-table': true,
};

export default privatePages;

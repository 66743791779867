import { useEffect } from 'react';
import createStore from 'ctx-provider';
import { useToggle } from 'react-use';
import { localStorageProperty } from '@itsa.io/web3utils';

const localStorageDarkMode = localStorageProperty('darkMode', {
	simpleType: true,
	encoded: false,
});

const storageDarkMode = localStorageDarkMode.get();

const DEFAULT_DARK_MODE =
	typeof storageDarkMode === 'boolean' ? storageDarkMode : false;

const useDarkmode = () => {
	const [darkmode, toggleDarkmode] = useToggle(DEFAULT_DARK_MODE);

	useEffect(() => {
		localStorageDarkMode.set(darkmode);
	}, [darkmode]);

	return {
		darkmode,
		toggleDarkmode,
	};
};

const store = createStore(useDarkmode);

export const { Provider } = store;
export default store.ctx;

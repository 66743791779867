import { createTheme, alpha } from '@itsa.io/ui';
import defaultTheme from './default-theme';
import colors from './colors';

export default createTheme({
	...defaultTheme,
	homeImage: 'url("/images/front-photo.webp")',
	homeImageY: 0,
	palette: {
		...colors,
		type: 'light',
		background: colors.grey[50],
		backgroundExtreme: colors.white,
		text: {
			primary: colors.grey[500],
			secondary: colors.grey[300],
		},
		primary: {
			light: colors.grey[100],
			main: colors.grey[400],
			dark: colors.grey[900],
			contrastText: colors.white,
		},
		secondary: {
			light: colors.grey[50],
			main: colors.grey[300],
			dark: colors.grey[500],
			contrastText: colors.white,
		},
		error: {
			light: colors.red[400],
			main: colors.red[600],
			dark: colors.red[800],
			contrastText: colors.white,
		},
		warning: {
			light: colors.orange[300],
			main: colors.orange[500],
			dark: colors.orange[700],
			contrastText: colors.white,
		},
		// Use defaults for info
		// info: {},
		success: {
			light: colors.green[100],
			main: colors.green[300],
			dark: colors.green[500],
			contrastText: colors.white,
		},
		default: {
			light: alpha(colors.white, 0.3),
			main: colors.white,
			dark: colors.grey[50],
			contrastText: colors.grey[500],
		},
	},
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: `${alpha(colors.white, 0.93)}!important`,
			},
		},
		MuiDrawer: {
			root: {
				// backgroundColor: colors.white,
			},
			paper: {
				backgroundColor: colors.white,
			},
		},
		MuiTabs: {
			root: {
				borderTop: `1px solid ${colors.grey[50]}`,
				backgroundColor: colors.white,
			},
		},
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: colors.grey[50],
			},
		},
		MuiListItem: {
			root: {
				'&:hover': {
					backgroundColor: `${alpha(colors.grey[50], 0.85)}!important`,
				},
				'&$selected': {
					backgroundColor: alpha(colors.grey[50], 0.85),
				},
			},
		},
		MuiListItemText: {
			root: {
				color: colors.grey[500],
			},
		},
		MuiButton: {
			outlined: {
				borderRadius: 20,
				borderColor: colors.grey[400],
				borderWidth: 2,
			},
			sizeLarge: {
				fontSize: 16,
				fontWeight: 600,
			},
			label: {
				textTransform: 'none',
			},
		},
		MuiIconButton: {
			root: {
				borderRadius: 6,
				'& .MuiTouchRipple-root *': {
					borderRadius: 6,
				},
			},
		},
		MuiSelect: {
			outlined: {
				padding: '11px 14px',
			},
		},
		MuiMenu: {
			list: {
				background: colors.white,
				borderRadius: 6,
			},
		},
		MuiMenuItem: {
			root: {
				'&$selected, &$selected:hover': {
					backgroundColor: alpha(colors.grey[50], 0.85),
				},
			},
		},
		MuiPickersBasePicker: {
			pickerView: {
				backgroundColor: colors.white,
			},
		},
		MuiTextField: {
			root: {
				'& .MuiInputLabel-root': {
					fontSize: 16,
					fontWeight: 600,
				},
			},
		},
		MuiFilledInput: {
			root: {
				'& .MuiFilledInput-input': {
					fontSize: 28,
					fontWeight: 600,
					color: colors.grey[800],
				},
				'&.MuiFilledInput-root, &.MuiFilledInput-root:hover': {
					color: colors.grey[300],
					border: `1px solid ${alpha(colors.white, 0.8)}`,
					backgroundColor: alpha(colors.white, 0.5),
					borderRadius: 6,
					// height: 78,
				},
				'&$focused': {
					'&.Mui-focused': {
						backgroundColor: alpha(colors.white, 0.5),
					},
				},
				'& .MuiInputAdornment-filled': {
					display: 'block',
					height: 'auto',
					maxHeight: 'none',
				},
			},
		},
		MuiFormHelperText: {
			root: {
				color: colors.grey[200],
				fontWeight: 600,
			},
		},
		MuiTableContainer: {
			root: {
				borderRadius: 12,
				border: `1px solid ${alpha(colors.white, 0.5)}`,
				backgroundColor: alpha(colors.white, 0.3),
			},
		},
		MuiTableCell: {
			root: {
				borderBottom: `1px solid ${alpha(colors.grey[100], 0.5)}`,
			},
			head: {
				fontSize: 12,
				fontWeight: 700,
				color: colors.grey[300],
				textTransform: 'uppercase',
				height: 65,
			},
			body: {
				color: colors.grey[400],
				borderBottom: `1px solid ${alpha(colors.grey[100], 0.5)}`,
			},
		},
		MuiAlert: {
			filledWarning: {
				backgroundColor: colors.orange[400],
			},
			filledInfo: {
				backgroundColor: colors.blue[400],
			},
			filledSuccess: {
				backgroundColor: colors.green[400],
			},
			filledError: {
				backgroundColor: colors.red[400],
			},
		},
		/*
		MuiOutlinedInput: {
			input: {
				'&:-webkit-autofill': {
					WebkitBoxShadow: `0 0 0 100px ${colors.grey[800]} inset`,
					WebkitTextFillColor: colors.white,
				},
			},
		},
		MuiFilledInput: {
			input: {
				'&:-webkit-autofill': {
					WebkitBoxShadow: `0 0 0 100px ${colors.grey[100]} inset`,
					WebkitTextFillColor: colors.grey[900],
				},
			},
		},
		*/
	},
});

import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	backdrop: {
		// backgroundColor: `${alpha(theme.palette.background, 0.9)}!important`,
	},
	container: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		width: '95vw',
		maxWidth: 650,
		maxHeight: '95vh',
		overflowY: 'auto',
		transform: 'translate(-50%, -50%)',
		outline: 0,
		backgroundColor: theme.palette.default.main,
		border: `1px solid ${alpha(theme.palette.primary.light, 0.2)}`,
		borderRadius: 6,
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 0,
		padding: theme.spacing(3, 0),
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
	},
	title: {
		position: 'relative',
		fontSize: 20,
		fontWeight: 600,
		margin: 0,
		textAlign: 'center',
		zIndex: 2,
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 16,
		textAlign: 'center',
		padding: theme.spacing(4, 3),
	},
	formControlLabel: {
		marginTop: theme.spacing(3),
	},
	disclaimer: {
		position: 'relative',
		width: '100%',
		marginTop: theme.spacing(3),
		paddingLeft: theme.spacing(3),
		'&::before': {
			position: 'absolute',
			display: 'block',
			content: '""',
			top: 8,
			left: 0,
			width: 8,
			height: 8,
			borderRadius: '50%',
			backgroundColor: theme.palette.primary.dark,
		},
	},
	wrapper: {
		maxWidth: 550,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'left',
	},
	desc: {
		fontSize: '0.85rem',
		fontWeight: 400,
		color: theme.palette.primary.main,
		marginTop: theme.spacing(1),
	},
	confirmText: {
		fontWeight: 900,
		marginBottom: theme.spacing(2),
	},
	walletLogoName: {
		color: theme.palette.text.primary,
		fontWeight: 600,
		'& > span': {
			color: theme.palette.orange[500],
		},
		textTransform: 'uppercase',
		letterSpacing: 2,
		fontSize: '1.2em', // NO rem -> needs to be effected by parent element
		marginTop: '8px',
		[theme.breakpoints.up('lg')]: {
			fontSize: '1.5em',
		},
	},
	buttonConfirm: {
		fontSize: 16,
		borderWidth: 1,
		color: theme.palette.common.black,
		backgroundColor: theme.palette.orange[800],
		marginTop: theme.spacing(3),
		'&:hover': {
			backgroundColor: theme.palette.orange[800],
		},
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
		color: theme.palette.common.white,
		zIndex: 2,
	},
	warningIcon: {
		display: 'flex',
		margin: theme.spacing(0, 'auto'),
		color: theme.palette.orange[600],
		fontSize: '3.1875rem',
	},
}));

import { makeStyles } from '@itsa.io/ui';

const logoWidth = 190;
const hamburgerBtnWidth = 48;

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	avatarButton: {
		padding: 4,
	},
	menuButtons: {
		display: 'flex',
		flexDirection: 'row',
		'& .MuiListItemText-root': {
			whiteSpace: 'nowrap',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	menuButtonsVertical: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiListItemText-root': {
			whiteSpace: 'nowrap',
		},
	},
	menuButton: {
		marginLeft: theme.spacing(0),
	},
	lastmenuButtons: {
		flexGrow: 1,
		justifyContent: 'flex-end',
		'& .MuiListItem-button': {
			width: 'auto',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		},
	},
	logo: {
		width: logoWidth,
	},
	logoHamburger: {
		width: logoWidth,
	},
	hamburger: {
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		},
	},
	logoContainer: {
		padding: 0,
		width: 'auto',
	},
	logoContainerUnOpened: {
		[theme.breakpoints.down('sm')]: {
			left: '50%',
			marginLeft: `calc(-${Math.round(
				logoWidth / 2,
			)}px - ${hamburgerBtnWidth}px)`,
			zIndex: 10,
		},
	},
	title: {
		fontWeight: 'bold',
		textTransform: 'uppercase',
		letterSpacing: 2,
		color: theme.palette.text.primary,
		flexGrow: 1,
		'& > span': {
			color: theme.palette.orange[500],
		},
	},
	navIconButton: {},
}));

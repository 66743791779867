import Web3 from 'web3';
import { JSON_APIS_WSS, RPC_URLS, NETWORK_NAMES, WS_TIMEOUT_SEC } from 'config';

const web3Instances = {};
const web3wsInstances = {};

const options = {
	// Enable auto reconnection
	clientConfig: {
		keepalive: true,
		keepaliveInterval: 20000,
	},
	reconnect: {
		auto: true,
		delay: 2500,
		onTimeout: true,
	},
	timeout: 1000 * WS_TIMEOUT_SEC,
};

export const getWeb3 = chainId => {
	if (!NETWORK_NAMES[chainId]) {
		return null;
	}
	if (!web3Instances[chainId]) {
		web3Instances[chainId] = new Web3(RPC_URLS[chainId]);
	}
	return web3Instances[chainId];
};

const createWebsocketInstance = chainId => {
	const wsProvider = new Web3.providers.WebsocketProvider(
		JSON_APIS_WSS[chainId],
		options,
	);
	return new Web3(wsProvider);
};

export const getWeb3ws = chainId => {
	if (!NETWORK_NAMES[chainId]) {
		return null;
	}
	if (!web3wsInstances[chainId]) {
		web3wsInstances[chainId] = createWebsocketInstance(chainId);
	}
	return web3wsInstances[chainId];
};

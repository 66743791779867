import { useEffect, useState, useRef, useContext } from 'react';
import createStore from 'ctx-provider';
import { DEFAULT_LOCKTIME_MINS, DEFAULT_LOCKTIME_ACTIVATED } from 'config';
import {
	later,
	localStorageProperty,
	cryptowalletCtx,
} from '@itsa.io/web3utils';

const localStorageLockTime = localStorageProperty('locktime', {
	simpleType: true,
	encoded: false,
});

const localStorageLockActivated = localStorageProperty('lockactivated', {
	simpleType: true,
	encoded: false,
});

const localStorageLockPassword = localStorageProperty('lockpassword', {
	simpleType: true,
});

const initialPassword = localStorageLockPassword.get() || '';
const initialLocktimeMin = localStorageLockTime.get() || DEFAULT_LOCKTIME_MINS;
const lsLockActivated = localStorageLockActivated.get();
const initialLockActivated =
	typeof lsLockActivated === 'boolean'
		? lsLockActivated
		: DEFAULT_LOCKTIME_ACTIVATED;

const useLocktimer = () => {
	const { chainId } = useContext(cryptowalletCtx);
	const [activated, setInternalActivated] = useState(initialLockActivated);
	const [password, setInternalPassword] = useState(initialPassword);
	const [locked, setLocked] = useState(false);
	const [locktimeMin, setLocalLocktimeMin] = useState(initialLocktimeMin);
	const [remainingSecs, setRemainingSecs] = useState(60 * locktimeMin);
	const remainingSecsRef = useRef(remainingSecs);
	const locktimeMinRef = useRef(locktimeMin);
	const lockedRef = useRef(locked);
	const activatedRef = useRef(activated);
	const timerRef = useRef();

	const setPassword = value => {
		setInternalPassword(value);
		localStorageLockPassword.set(value);
	};

	const setLocktimeMin = min => {
		localStorageLockTime.set(min);
		setLocalLocktimeMin(min);
	};

	const setActivated = value => {
		setInternalActivated(value);
		localStorageLockActivated.set(value);
	};

	const resetTimer = force => {
		if ((force || !lockedRef.current) && activatedRef.current) {
			setRemainingSecs(60 * locktimeMinRef.current);
		}
	};

	const unlock = value => {
		const success = value.toLowerCase() === password.toLowerCase();
		if (success) {
			resetTimer();
			setLocked(false);
		}
		return success;
	};

	const lock = () => {
		setLocked(true);
		resetTimer(true);
	};

	const startCountdown = () => {
		if (!timerRef.current && chainId) {
			timerRef.current = later(
				() => {
					// we are using Refs, because statevalues are undefined in this stage
					if (!lockedRef.current) {
						let newRemainingSecs = remainingSecsRef.current;
						if (newRemainingSecs > 0) {
							newRemainingSecs -= 1;
							setRemainingSecs(newRemainingSecs);
							if (newRemainingSecs <= 0) {
								lock();
							}
						}
					}
				},
				1000,
				true,
			);
		}
	};

	const stopCountdown = () => {
		if (timerRef.current) {
			timerRef.current.cancel();
			timerRef.current = null;
		}
	};

	useEffect(() => {
		remainingSecsRef.current = remainingSecs;
	}, [remainingSecs]);

	useEffect(() => {
		locktimeMinRef.current = locktimeMin;
	}, [locktimeMin]);

	useEffect(() => {
		lockedRef.current = locked;
	}, [locked]);

	useEffect(() => {
		activatedRef.current = activated;
		resetTimer();
		if (activated) {
			startCountdown();
		} else {
			stopCountdown();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activated]);

	const checkPreventAction = e => {
		if (lockedRef.current) {
			// locked, we may need to prevent from clicking or entering keyboard
			let node = e.target;
			while (
				node &&
				typeof node.getAttribute === 'function' &&
				node.getAttribute('id') !== 'lockscreen'
			) {
				node = node.parentNode;
			}
			if (!node) {
				e.preventDefault();
				e.stopPropagation();
				e.stopImmediatePropagation();
			}
		}
	};

	const setListeners = () => {
		const doc = document;
		doc.addEventListener('mousedown', resetTimer);
		doc.addEventListener('mousemove', resetTimer);
		doc.addEventListener('mouseup', resetTimer);
		doc.addEventListener('touchstart', resetTimer);
		doc.addEventListener('touchmove', resetTimer);
		doc.addEventListener('touchend', resetTimer);
		doc.addEventListener('keydown', resetTimer);
		doc.addEventListener('keydown', checkPreventAction);
		doc.addEventListener('click', checkPreventAction);
	};

	const removeListeners = () => {
		const doc = document;
		doc.removeEventListener('mousedown', resetTimer);
		doc.removeEventListener('mousemove', resetTimer);
		doc.removeEventListener('mouseup', resetTimer);
		doc.removeEventListener('touchstart', resetTimer);
		doc.removeEventListener('touchmove', resetTimer);
		doc.removeEventListener('touchend', resetTimer);
		doc.removeEventListener('keydown', resetTimer);
		doc.removeEventListener('keydown', checkPreventAction);
		doc.removeEventListener('click', checkPreventAction);
	};

	useEffect(() => {
		if (activated) {
			startCountdown();
		}
		setListeners();
		return () => {
			removeListeners();
			stopCountdown();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (activated) {
			if (chainId) {
				startCountdown();
			} else {
				stopCountdown();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId]);

	return {
		setLocktimeMin,
		locktimeMin,
		remainingSecs,
		locked: locked && activated,
		unlock,
		lock,
		activated,
		setActivated,
		password,
		setPassword,
	};
};

const store = createStore(useLocktimer);

export const { Provider } = store;
export default store.ctx;

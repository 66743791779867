import React, { useState, useContext } from 'react';
import {
	FormControlLabel,
	Checkbox,
	Button,
	IconButton,
	Modal as MuiModal,
	Fade,
} from '@itsa.io/ui';
import { Warning as WarningIcon, Close as CloseIcon } from '@material-ui/icons';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import useStyles from 'styles/components/common/modals/DisclaimerModal';
import disclaimermodalCtx from 'context/disclaimermodal';
import getDisclaimerItems from 'config/disclaimer-items';

const DisclaimerModal = () => {
	const { t } = useIntl();
	const classes = useStyles();
	const { visible, close, complied } = useContext(disclaimermodalCtx);
	const { chainId } = useContext(cryptowalletCtx);
	const [checked, setChecked] = useState(complied);

	const filterDisclaimer = getDisclaimerItems(t).filter(
		item => item.group === 'general' || item.group === chainId?.toString(),
	);

	const handleChange = event => {
		setChecked(event.target.checked);
	};

	const handleClose = () => {
		close(false);
	};

	const handleContinue = () => {
		close(true);
	};

	const disclaimerContent = filterDisclaimer.map(item => (
		<div className={classes.disclaimer} key={item.id}>
			<div>{item.label}</div>
			<div className={classes.desc}>{item.desc}</div>
		</div>
	));

	const warningIcon = <WarningIcon className={classes.warningIcon} />;

	const header = (
		<header className={classes.header}>
			{warningIcon}
			<h2 className={classes.title}>
				<div className={classes.walletLogoName}>
					{t('common.itsa')}
					<span>{t('common.wallet')}</span>
				</div>
				Disclaimer
			</h2>
		</header>
	);

	const closeIcon = (
		<IconButton
			className={classes.closeButton}
			color="primary"
			onClick={handleClose}
		>
			<CloseIcon color="primary" />
		</IconButton>
	);

	return (
		<MuiModal open={!!visible} closeAfterTransition>
			<Fade
				timeout={{
					enter: 500,
					exit: 300,
				}}
				in={visible}
			>
				<div className={classes.container}>
					{closeIcon}
					{header}
					<div className={classes.body}>
						<div className={classes.confirmText}>
							{t('disclaimer_modal.warning_text')}
						</div>
						<div className={classes.wrapper}>{disclaimerContent}</div>
						<FormControlLabel
							className={classes.formControlLabel}
							control={
								<Checkbox
									checked={checked}
									onChange={handleChange}
									color="primary"
								/>
							}
							label={t('disclaimer_modal.primary_checkbox')}
						/>

						<Button
							className={classes.buttonConfirm}
							variant="contained"
							onClick={handleContinue}
							disabled={!checked}
						>
							{t('disclaimer_modal.continue')}
						</Button>
					</div>
				</div>
			</Fade>
		</MuiModal>
	);
};

export default DisclaimerModal;

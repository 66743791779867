import 'styles/main.css';
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Container,
	CssBaseline,
	MuiThemeProvider,
	Backdrop,
	Seo,
} from '@itsa.io/ui';
import {
	useIntl,
	later,
	walletEvents,
	localStorageProperty,
	cryptowalletCtx,
} from '@itsa.io/web3utils';
import { Info as InfoIcon } from '@itsa.io/ui/icons';
import GlobalContext from 'components/layout/GlobalContext';
import Header from 'components/layout/Header';
import OfflineModal from 'components/common/modals/OfflineModal';
import DisclaimerModal from 'components/common/modals/DisclaimerModal';
import AlertModal from 'components/common/modals/AlertModal';
import WalletConnect from 'components/common/WalletConnect';
import usePageMeta from 'hooks/usePageMeta';
import useOnline from 'hooks/useOnline';
import lightTheme from 'styles/light-theme';
import darkTheme from 'styles/dark-theme';
import darkmodeCtx from 'context/darkmode'; // in this stage, it is not yet bacome part of the ContextProvider
import feedbackCtx from 'context/feedback';
import latestsystemmessageCtx from 'context/latestsystemmessage';
import useStyles from 'styles/components/layout/Layout';
import useAlert from 'hooks/useAlert';
import {
	SEVERITIES,
	ENVIRONMENT,
	TIMEOUT_INTRO_MESSAGE,
	NETWORK_NAMES,
	EXPECTED_NETWORK_ID,
} from 'config';
import PRIVATE_PAGES from 'config/private-pages';
// import { navigate } from 'gatsby';

const ALERTS = {};

const lsIntroduction = localStorageProperty('introduction', {
	simpleType: true, // we use a boolean
	encoded: false,
});

const Layout = props => (
	<GlobalContext pageProps={props}>
		<LayoutInner {...props} />
	</GlobalContext>
);

// CAUTIOUS: this is `LAYOUT` and Gatsby will use SSR during production, which combines classes
// therefore, only in `LAYOUT`, we CANNOT dynamically change the css
const LayoutInner = props => {
	const { t } = useIntl();
	const alert = useAlert();
	const online = useOnline();
	const { darkmode } = useContext(darkmodeCtx);
	const { feedbackMsg, feedbackHasBackdrop } = useContext(feedbackCtx);
	const latestsystemmessage = useContext(latestsystemmessageCtx);
	const { pageTitle, pageDescription } = usePageMeta(props);
	const currentTheme = darkmode ? darkTheme : lightTheme;
	const classes = useStyles({ currentTheme });
	const timer = useRef();
	const nextMessage = useRef(false);
	const suppressServerMsgRef = useRef(false);
	// const [, forceUpdate] = useReducer(x => x + 1, 0);
	const { chainId, connected, switchToNetwork } = useContext(cryptowalletCtx);
	const isPrivatePage = PRIVATE_PAGES[props.uri];
	const needsWalletConnect = isPrivatePage && !connected;
	let leftSidebar;
	let tabsContent;
	let pageContent;

	const header = <Header {...props} />;
	console.debug('needsWalletConnect', needsWalletConnect);
	if (needsWalletConnect) {
		pageContent = (
			<div className={classes.loginContainer}>
				<WalletConnect {...props} />
			</div>
		);
	} else {
		pageContent = props.children;
	}

	const setWalletListeners = () => {
		const levels = Object.keys(SEVERITIES);
		levels.forEach(level => {
			const eventName = SEVERITIES[level];
			walletEvents.on(eventName, (message, needsToSign) => {
				if (message) {
					const cleanup = level === '-1';
					const isSigned = level === '-2';
					const isCancelled = level === '-3';
					if (isSigned) {
						// navigate(props.uri);
					} else if (cleanup) {
						const alertPromise = ALERTS[message];
						if (alertPromise) {
							alertPromise.close();
							delete ALERTS[message];
						}
					} else if (isCancelled) {
						// eslint-disable-next-line no-console
						console.debug('cancelled');
					} else {
						const alertPromise = alert(message, {
							severity: SEVERITIES[level] || 'success',
							noDupes: true,
							sender: 'cryptowallet',
							match: message,
							timeout: 0,
							needsToSign,
						});
						ALERTS[message] = alertPromise;
					}
				}
			});
		});
	};

	const removeWalletListeners = () => {
		const levels = Object.keys(SEVERITIES);
		levels.forEach(level => {
			const eventName = SEVERITIES[level];
			walletEvents.removeAllListeners(eventName);
		});
	};

	const handleIntroductionMsg = () => {
		const introductionVal = lsIntroduction.get();
		const needsIntroduction =
			typeof introductionVal === 'boolean' ? introductionVal : true;
		if (needsIntroduction) {
			suppressServerMsgRef.current = true;
			alert(t('layout.intro_message'), {
				severity: 'info',
				timeout: TIMEOUT_INTRO_MESSAGE,
			});
			lsIntroduction.set(false);
			timer.current = later(() => {
				suppressServerMsgRef.current = false;
			}, 60000);
		}
	};

	const selectNetwork = () => {
		switchToNetwork(EXPECTED_NETWORK_ID);
	};

	useEffect(() => {
		// first time should be ignored: that will be called with the initial value; not by making any changes
		if (nextMessage.current) {
			if (!suppressServerMsgRef.current) {
				alert(latestsystemmessage, {
					severity: 'info',
					timeout: TIMEOUT_INTRO_MESSAGE,
				});
			}
		} else {
			nextMessage.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestsystemmessage]);

	useEffect(() => {
		const htmlNode = document.documentElement;
		// by timer: goto cryptowallet page, but only if not native mobile app
		htmlNode.setAttribute('data-visible', 'true');
		setWalletListeners();
		handleIntroductionMsg();
		// if (needsWalletConnect) {
		// 	setTimeout(() => {
		// 		console.debug('FORCEUPDATE');
		// 		forceUpdate();
		// 	}, 2000);
		// }
		return () => {
			if (timer.current) {
				timer.current.cancel();
			}
			removeWalletListeners();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let developmentLabel;
	if (ENVIRONMENT === 'development') {
		developmentLabel = (
			<div className={classes.developmentLabel}>Development</div>
		);
	}

	let feedback;
	let wrongNetworkMsg;
	if (isPrivatePage && connected && EXPECTED_NETWORK_ID !== chainId) {
		let action;
		// only if we can switch to ETH, we will show the Switch Button
		// because MM-API cannot yet switch to the Sepolia network
		if (EXPECTED_NETWORK_ID === 1) {
			action = (
				<Button onClick={selectNetwork}>
					{t('common.select_network', {
						values: {
							network: NETWORK_NAMES[EXPECTED_NETWORK_ID].toUpperCase(),
						},
					})}
				</Button>
			);
		}
		wrongNetworkMsg = (
			<AlertModal
				action={action}
				classes={classes.wrongNetwork}
				icon={InfoIcon}
				message={t('common.switch_network', {
					values: { network: NETWORK_NAMES[EXPECTED_NETWORK_ID].toUpperCase() },
				})}
				severity="info"
			/>
		);
	} else {
		feedback = feedbackMsg;
	}

	return (
		<MuiThemeProvider theme={currentTheme}>
			<CssBaseline />
			<Seo title={pageTitle} description={pageDescription} />
			<Backdrop className={classes.backdrop} open={!!feedbackHasBackdrop} />
			{header}
			{developmentLabel}
			<div className={classes.root}>
				{leftSidebar}
				<Container className={classes.container} component="main" maxWidth="lg">
					{pageContent}
					<DisclaimerModal />
					{feedback}
					{wrongNetworkMsg}
					<OfflineModal
						show={!online}
						message={t(
							online ? 'offline_modal.online' : 'offline_modal.offline',
						)}
					/>
				</Container>
				{tabsContent}
			</div>
		</MuiThemeProvider>
	);
};

LayoutInner.propTypes = {
	location: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
	uri: PropTypes.string.isRequired,
};

export default Layout;

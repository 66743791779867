import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	// root: {
	// 	display: 'flex',
	// 	width: '100%',
	// 	maxWidth: 500,
	// 	flexDirection: 'column',
	// 	justifyContent: 'center',
	// 	alignItems: 'center',
	// 	textAlign: 'center',
	// },
	metaMaskIcon: {
		width: 150,
		height: 150,
		marginTop: theme.spacing(2),
	},
	signIn: {
		width: '100%',
		maxWidth: 150,
		marginTop: theme.spacing(5),
	},
	separateLine: {
		width: '100%',
		maxWidth: 350,
		borderColor: theme.palette.default.light,
		margin: theme.spacing(4, 0, 2, 0),
	},
	iconButtonContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	iconButton: {
		color: theme.palette.primary.main,
		fill: theme.palette.primary.main,
	},
	twitterIcon: {
		fontSize: 25,
	},
	discordIcon: {
		width: 25,
		height: 25,
	},
	metamaskRoot: {
		display: 'flex',
		alignItems: 'center',
		width: '95vw',
		maxWidth: 550,
		maxHeight: '95vh',
		overflowY: 'auto',
		flexDirection: 'column',
		textAlign: 'center',
		borderRadius: 6,
		padding: theme.spacing(3, 0),
		// backgroundColor: theme.palette.default.main,
		// border: `1px solid ${alpha(theme.palette.primary.light, 0.2)}`,
	},
	walletMainImage: {
		display: 'none',
	},
	metamaskTitle: {},
	metamaskCopied: {
		padding: theme.spacing(0.5),
	},
	metamaskIcon: {
		marginBottom: theme.spacing(1),
	},
	metamaskIconTitle: {
		fontWeight: 600,
	},
	metamaskTitleNetwork: {
		marginTop: theme.spacing(1),
		color: theme.palette.red[800],
	},
	metamaskDescription: {
		color: theme.palette.text.primary,
	},
	metamaskAddressDescription: {
		fontSize: 12,
		fontWeight: 600,
		color: alpha(theme.palette.primary.main, 0.7),
		[theme.breakpoints.up('lg')]: {
			fontSize: 14,
		},
	},
	metamaskIconBox: {
		width: '100%',
		maxWidth: 300,
	},
	metamaskIconBoxInner: {
		color: alpha(theme.palette.primary.main, 0.7),
		'&:hover': {
			color: theme.palette.primary.main,
		},
		'&:hover > span': {
			textDecoration: 'underline',
		},
	},
	metamaskSelect: {
		maxWidth: 230,
	},
	metamaskSelectRoot: {
		color: alpha(theme.palette.primary.main, 0.7),
	},
}));

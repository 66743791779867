import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		width: '100%',
		zIndex: 9997,
		maxWidth: 600,
	},
	alert: {
		width: '100%',
		fontSize: 16,
		fontWeight: 600,
		padding: theme.spacing(1.5, 2),
		borderRadius: 6,
		color: '#212121',
		backgroundColor: theme.palette.common.white,
	},
	refreshButton: {
		fontWeight: 600,
		color: theme.palette.common.white,
		padding: theme.spacing(0.4, 2),
		backgroundColor: '#263238',
		'&:hover': {
			backgroundColor: '#263238',
		},
	},
}));

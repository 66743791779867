import { useEffect, useState } from 'react';
import createStore from 'ctx-provider';
import { localStorageProperty } from '@itsa.io/web3utils';
import {
	DEFAULT_EXTRA_HARDWARE_GASPRICE,
	MAX_EXTRA_GASPRICE_HARDWARE_WALLET,
} from 'config';

const localStorageExtraGaspriceHardwareWallet = localStorageProperty(
	'extragaspricehardwarewallet',
	{
		simpleType: true, // we use a number
		encoded: false,
	},
);

const storageExtraGaspriceHardwareWallet =
	localStorageExtraGaspriceHardwareWallet.get();

const DEFAULT_EXTRA_GASPRICE_HARDWARE_WALLET =
	typeof storageExtraGaspriceHardwareWallet === 'number'
		? storageExtraGaspriceHardwareWallet
		: DEFAULT_EXTRA_HARDWARE_GASPRICE;

const useExtraGaspriceHardwareWallet = () => {
	const [extraGaspriceHardwareWallet, setExtraGaspriceHardwareWallet] =
		useState(DEFAULT_EXTRA_GASPRICE_HARDWARE_WALLET);

	useEffect(() => {
		localStorageExtraGaspriceHardwareWallet.set(extraGaspriceHardwareWallet);
	}, [extraGaspriceHardwareWallet]);

	useEffect(() => {
		// perhaps from previous higher MAX_EXTRA_GASPRICE_HARDWARE_WALLET setting
		if (
			DEFAULT_EXTRA_GASPRICE_HARDWARE_WALLET >
			MAX_EXTRA_GASPRICE_HARDWARE_WALLET
		) {
			setExtraGaspriceHardwareWallet(MAX_EXTRA_GASPRICE_HARDWARE_WALLET);
		}
	}, []);

	return {
		extraGaspriceHardwareWallet,
		setExtraGaspriceHardwareWallet,
	};
};

const store = createStore(useExtraGaspriceHardwareWallet);

export const { Provider } = store;
export default store.ctx;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-close-the-table-jsx": () => import("./../../../src/pages/close-the-table.jsx" /* webpackChunkName: "component---src-pages-close-the-table-jsx" */),
  "component---src-pages-fund-a-home-jsx": () => import("./../../../src/pages/fund-a-home.jsx" /* webpackChunkName: "component---src-pages-fund-a-home-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-my-properties-details-jsx": () => import("./../../../src/pages/my-properties-details.jsx" /* webpackChunkName: "component---src-pages-my-properties-details-jsx" */),
  "component---src-pages-my-properties-jsx": () => import("./../../../src/pages/my-properties.jsx" /* webpackChunkName: "component---src-pages-my-properties-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-tokenize-property-jsx": () => import("./../../../src/pages/tokenize-property.jsx" /* webpackChunkName: "component---src-pages-tokenize-property-jsx" */),
  "component---src-pages-transfer-agents-jsx": () => import("./../../../src/pages/transfer-agents.jsx" /* webpackChunkName: "component---src-pages-transfer-agents-jsx" */),
  "component---src-pages-view-listings-details-jsx": () => import("./../../../src/pages/view-listings-details.jsx" /* webpackChunkName: "component---src-pages-view-listings-details-jsx" */),
  "component---src-pages-view-listings-jsx": () => import("./../../../src/pages/view-listings.jsx" /* webpackChunkName: "component---src-pages-view-listings-jsx" */)
}


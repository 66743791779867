import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	AppBar,
	Avatar,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	SwipeableDrawer,
	Tooltip,
} from '@itsa.io/ui';
import darkmodeCtx from 'context/darkmode';
import {
	Menu as MenuIcon,
	Brightness5 as Brightness5Icon,
	Brightness2 as Brightness2Icon,
} from '@itsa.io/ui/icons';

import { LOGO_URL } from 'config';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import useStyles from 'styles/components/layout/Header';
import { navigate } from 'gatsby';
import PRIVATE_PAGES from 'config/private-pages';

const Header = props => {
	const classes = useStyles();
	const { t } = useIntl();
	const { darkmode, toggleDarkmode } = useContext(darkmodeCtx);
	const { connected, disconnect } = useContext(cryptowalletCtx);
	const [open, setOpen] = useState(false);
	const [openUserManagement, setOpenUserManagement] = useState(false);
	const [showUserToolTip, setShowUserToolTip] = useState(false);

	const handleOpenNavMenu = (e, isOpen) => {
		if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
			return;
		}
		setOpen(!!isOpen);
	};

	const handleOpenUserMenu = (e, isOpen) => {
		if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
			return;
		}
		setShowUserToolTip(false);
		setOpenUserManagement(!!isOpen);
	};

	const logout = () => {
		setOpenUserManagement(false);
		disconnect();
		if (PRIVATE_PAGES[props.uri]) {
			navigate('/');
		}
	};

	const keyLynx = (
		<ListItem
			button
			className={clsx(classes.logoContainer, {
				[classes.logoContainerUnOpened]: !open,
			})}
			component="a"
			href="/"
		>
			<img
				alt="keylinx-logo"
				className={open ? classes.logoHamburger : classes.logo}
				src={LOGO_URL}
			/>
		</ListItem>
	);

	const menuItems = [
		<ListItem button component="a" key="3" href="/tokenize-property">
			<ListItemText primary={t('menu.tokenize')} />
		</ListItem>,
		<ListItem button component="a" key="2" href="/my-properties">
			<ListItemText primary={t('menu.my_properties')} />
		</ListItem>,
		<ListItem button component="a" key="1" href="/view-listings">
			<ListItemText primary={t('menu.buy')} />
		</ListItem>,
		<ListItem button component="a" key="4" href="/transfer-agents">
			<ListItemText primary={t('menu.transfer_agents')} />
		</ListItem>,
	];

	const menu = <List className={classes.menuButtons}>{menuItems}</List>;

	const hamburgerMenu = (
		<>
			<IconButton
				onClick={event => handleOpenNavMenu(event, true)}
				// color="inherit"
				className={classes.hamburger}
			>
				<MenuIcon />
			</IconButton>
			<SwipeableDrawer
				classes={{
					paper: classes.paperRoot,
				}}
				anchor="left"
				open={open}
				onClose={event => handleOpenNavMenu(event, false)}
				onOpen={event => handleOpenNavMenu(event, true)}
			>
				<div role="presentation">
					{keyLynx}
					<Divider />
					<List className={classes.menuButtonsVertical}>{menuItems}</List>
				</div>
			</SwipeableDrawer>
		</>
	);

	let avatarOrLogin;
	const handleCloseUserToolTip = () => {
		setShowUserToolTip(false);
	};

	const handleOpenUserToolTip = () => {
		if (!openUserManagement) {
			setShowUserToolTip(true);
		}
	};
	if (connected) {
		const fullUserName = 'Remy Sharp';

		const menuItemsUserManagement = [
			<ListItem button component="a" key="1" href="/profile">
				<ListItemText primary={t('menu.edit_profile')} />
			</ListItem>,
			<ListItem button onClick={logout} key="2">
				<ListItemText primary={t('menu.logout')} />
			</ListItem>,
		];

		avatarOrLogin = (
			<Tooltip
				className={classes.lastmenuButton}
				onClose={handleCloseUserToolTip}
				onOpen={handleOpenUserToolTip}
				open={showUserToolTip}
				title={t('menu.manage_user')}
			>
				<div>
					<IconButton
						className={clsx(classes.iconButton, classes.avatarButton)}
						onClick={event => handleOpenUserMenu(event, true)}
					>
						<Avatar alt={fullUserName} src="/images/avatar.png" />
					</IconButton>
					<SwipeableDrawer
						classes={{
							paper: classes.paperRoot,
						}}
						anchor="right"
						open={openUserManagement}
						onClose={event => handleOpenUserMenu(event, false)}
						onOpen={event => handleOpenUserMenu(event, true)}
					>
						<div role="presentation">
							<List className={classes.menuButtonsVertical}>
								{menuItemsUserManagement}
							</List>
						</div>
					</SwipeableDrawer>
				</div>
			</Tooltip>
		);
	} else {
		avatarOrLogin = (
			<ListItem button component="a" href="/login">
				<ListItemText primary={t('menu.signin')} />
			</ListItem>
		);
	}

	const rightMenu = (
		<List className={clsx(classes.menuButtons, classes.lastmenuButtons)}>
			{avatarOrLogin}
			<IconButton className={classes.iconButton} onClick={toggleDarkmode}>
				{darkmode ? <Brightness2Icon /> : <Brightness5Icon />}
			</IconButton>
		</List>
	);

	return (
		<AppBar className={classes.root}>
			{hamburgerMenu}
			{keyLynx}
			{menu}
			{rightMenu}
		</AppBar>
	);
};

Header.defaultProps = {};

Header.propTypes = {
	uri: PropTypes.string.isRequired,
};

export default Header;

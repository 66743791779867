/* eslint-disable prefer-destructuring */
import { useRef, useEffect, useContext, useState } from 'react';
import createStore from 'ctx-provider';
import { cryptowalletCtx } from '@itsa.io/web3utils';
import pendingTransactions from 'api/pending-transactions';

const usePendingTransactions = () => {
	const { chainId, address } = useContext(cryptowalletCtx);
	const transactionsCallbackRef = useRef();
	const [data, setData] = useState([]);

	const onUpdate = async () => {
		setData(pendingTransactions.pendingTransactions);
	};

	const startWatching = async () => {
		if (chainId && address) {
			if (transactionsCallbackRef.current) {
				pendingTransactions.stop(transactionsCallbackRef.current);
			}
			transactionsCallbackRef.current = onUpdate;
			pendingTransactions.start(
				chainId,
				address,
				transactionsCallbackRef.current,
			);
		} else if (transactionsCallbackRef.current) {
			pendingTransactions.stop(transactionsCallbackRef.current);
			transactionsCallbackRef.current = null;
			setData([]);
		}
	};

	useEffect(() => {
		startWatching();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, address]);

	return data;
};

const store = createStore(usePendingTransactions);

export const { Provider } = store;
export default store.ctx;

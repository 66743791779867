import { toBN } from '@itsa.io/web3utils';

export const ENVIRONMENT = process.env.GATSBY_ENV;

export const BN_ZERO = toBN('0');
export const BN_1 = toBN('1');
export const BN_3 = toBN('3');
export const BN_100 = toBN('100');
export const BN_914 = toBN('914');
export const BN_997 = toBN('997');
export const BN_1000 = toBN('1000');
export const BN_10000 = toBN('10000');
export const BN_100000 = toBN('100000');
export const BN_1000000 = toBN('1000000');
export const BN_1MNRG = toBN('1000000000000000000000');
export const BN_GAS_SEND_COIN = toBN('21000');
export const BN_1ETH_WEI = toBN('1000000000000000000');

export const SEVERITIES = {
	'-3': 'cancelled', // event that will be send by wallet devices whenever a transaction is cancelled
	'-2': 'signed', // event that will be send by wallet devices whenever a transaction is successfully signed
	'-1': 'cleanup', // event that will be send by wallet devices whenever a previous message should be cleaned up
	0: 'success',
	1: 'info',
	2: 'warning',
	3: 'error',
};

export const BN_MAXUINT256 = toBN(
	'0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
);

export const PAGES_NAMES = {
	HOME: 'indexPage',
	SETTINGS: 'SettingsPage',
};

export const EXPECTED_NETWORK_ID = ENVIRONMENT === 'production' ? 1 : 11155111;

const TRANSFORM_SYMBOLS = {
	WETH: 'ETH',
};

const TRANSFORM_COINNAMES = {
	WETH: 'Ethereum',
};

export const CHAIN_SYMBOLS = {
	1: 'ETH',
};

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const RPC_URLS = {
	1: process.env.GATSBY_ETHEREUM_JSON_RCP,
	11155111: 'wss://rpc.sepolia.ethpandaops.io', // use the wss connection, to avoid cors issues
};

export const JSON_APIS_WSS = {
	1: process.env.GATSBY_ETHEREUM_WSS,
	11155111: 'wss://rpc.sepolia.ethpandaops.io',
};

// ensure the addresses are lowercased, since they will be compared to later on in lowercase
export const WRAPPED_ADDRESSES = {
	1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'.toLowerCase(),
	11155111: '0xAb85c4076A0087252C694CB78A282715241269f1'.toLowerCase(),
};

export const NETWORK_NAMES = {
	// only networks that the wallet supports!!
	1: 'Ethereum',
	// 5: 'Goerli',
	11155111: 'Sepolia',
};

export const NETWORK_ICONS = {
	// only networks that the wallet supports!!
	1: '/tokens/ETH.svg',
	11155111: '/tokens/ETH.svg',
};

export const EXPLORER_URLS = {
	1: 'https://etherscan.io',
	11155111: 'https://sepolia.etherscan.io',
};

export const MULTICALL_ADDRESSES = {
	1: '0x5ba1e12693dc8f9c48aad8770482f4739beed696', // multicall version 2
};

export const CURRENCIES = [
	'aud',
	'cad',
	'chf',
	'cny',
	'dkk',
	'eur',
	'gbp',
	'huf',
	'idr',
	'jpy',
	'nok',
	'pln',
	'rub',
	'sek',
	'thb',
	'try',
	'usd',
	'zar',
];

export const LISTINFOS = [
	'none',
	'balance',
	'fiat',
	'tokenprice',
	'dextokenprice', // NOT at this moment: would need extra SC calls
];

export const SOUNDS = [
	'ping',
	'pong',
	'coinCollect',
	'success',
	'sonar',
	'steelcup',
	'harp',
]; // names should reference the filenames in /src/assets/sounds

export const getMainSymbol = symbol => TRANSFORM_SYMBOLS[symbol] || symbol;

export const getMainName = (name, symbol) =>
	TRANSFORM_COINNAMES[symbol] || name;

export const IS_TESTNET = {
	3: true,
};

export const TOKEN_TEMPLATES = {
	1: {
		address: WRAPPED_ADDRESSES[1],
		balance: BN_ZERO,
		contract_type: 'ERC20',
		decimals: 18,
		image: '/tokens/ETH.svg',
		name: 'Wrapped Ether',
		symbol: 'WETH',
		visualDecimals: 4,
	},
};

export const PLACEHOLDER_ICON = '/tokens/placeholder.svg';
export const DISCORD_URL = 'https://discord.com/invite/keylynx/';
export const TWITTER_URL = 'https://twitter.com/keylynx/';
export const MAIL_TO = 'mailto:inbox@keylynx.realestate';

export const HEX0 = '0x0';

export const FONT_SIZE_DECIMALS = '0.75em';

export const GAS_PERCENTAGES = {
	// when going above 40%, it could be hardware txs will fail
	1: -10,
	2: -5,
	3: 0,
	4: 5,
	5: 10,
	6: 15,
	7: 20,
	8: 25,
	9: 30,
};

export const DEFAULT_EXTRA_HARDWARE_GASPRICE = 4;
export const MAX_EXTRA_GASPRICE_HARDWARE_WALLET = Object.keys(
	GAS_PERCENTAGES,
).reduce((cum, item) => Math.max(cum, item), 0);

export const DEFAULT_LOCKTIME_ACTIVATED = true;
export const DEFAULT_LOCKTIME_MINS = 2;
export const SHOW_ABOUT_TO_SCREENLOCK_REMAINING_SEC = 60;

export const TIMEOUT_COPY_MESSAGE = 1500; // ms
export const GENERAL_MESSAGE_TIMEOUT = 4000; // ms
export const TIMEOUT_SETTINGS_MESSAGE = 20000; // ms
export const TIMEOUT_INFORM_EXCHANGE_MESSAGE = 20000; // ms
export const TIMEOUT_INTRO_MESSAGE = 30000; // ms
export const WS_TIMEOUT_SEC = 30; // sec, note that 15 sec is the default value for Web3.providers.WebsocketProvider

export const API_BASE_URL = 'https://api.keylynx.realestate';
export const API_URL_SYSTEMMESSAGES = `${API_BASE_URL}/systemmessages`;
// export const API_URL_PROPERTIES = `${API_BASE_URL}/properties`;
export const API_URL_PROPERTIES =
	'https://api.housecanary.com/v2/property/value';

export const API_USERNAME = 'test_3BAXNSKSRXJU9BGCP372';
export const API_PASSWORD = 'hMOId0OC5lMEJ9vHdQ4YnyNbKyJdoW9j';
export const TEST_API_USERNAME = 'test_3BAXNSKSRXJU9BGCP372';
export const TEST_API_PASSWORD = 'hMOId0OC5lMEJ9vHdQ4YnyNbKyJdoW9j';

export const LOGO_URL = '/images/logos/svg/keylinx-lightblue.svg';

import { useEffect, useState, useContext } from 'react';
import createStore from 'ctx-provider';
import blockheightCtx from 'context/blockheight';
import { cryptowalletCtx, toBN } from '@itsa.io/web3utils';
import getCurrentGasPrice from 'utils/get-current-gas-price';
import { BN_ZERO, BN_GAS_SEND_COIN, NETWORK_NAMES } from 'config';

const getHighestGas = () => {
	return BN_GAS_SEND_COIN;
};

const useGas = () => {
	const { chainId } = useContext(cryptowalletCtx);
	const { blockheight } = useContext(blockheightCtx);
	const [gasprice, setGasprice] = useState();
	const [minRetainedWei, setMinRetainedWei] = useState(BN_ZERO);

	const setCurrentGasPrice = async () => {
		let currentGasPrice = await getCurrentGasPrice(chainId);
		currentGasPrice = toBN(currentGasPrice);
		setGasprice(currentGasPrice);
		const wei = currentGasPrice.mul(getHighestGas(chainId));
		setMinRetainedWei(wei);
	};

	useEffect(() => {
		if (NETWORK_NAMES[chainId]) {
			setCurrentGasPrice();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, blockheight]);

	return {
		gasprice,
		minRetainedWei,
	};
};

const store = createStore(useGas);

export const { Provider } = store;
export default store.ctx;

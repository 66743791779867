const DISCLAIMER_ITEMS = {
	general: [
		{
			label: 'disclaimer_modal.checkbox_label1',
			desc: '',
		},
		{
			label: 'disclaimer_modal.checkbox_label2',
			desc: 'disclaimer_modal.checkbox_label2_example',
		},
	],
	1: [
		// chainId = 1
		{
			label: 'disclaimer_modal.ethereum.checkbox_label1',
			desc: 'disclaimer_modal.ethereum.checkbox_label1_example',
		},
	],
};

const sortFn = (a, b) => {
	if (a === 'general') {
		return -1;
	}
	if (b === 'general') {
		return 1;
	}
	return 0;
};

const getDisclaimerItems = (t, config) => {
	const disclaimerItems = [];
	const groupKeys = Object.keys(DISCLAIMER_ITEMS);
	let i = 0;

	groupKeys.sort(sortFn);
	groupKeys.forEach(groupItem => {
		DISCLAIMER_ITEMS[groupItem].forEach(item => {
			const newItem = {
				group: groupItem,
			};
			newItem.id = i;
			newItem.label = item.label ? t(item.label, config) : '';
			newItem.desc = item.desc ? t(item.desc, config) : '';
			newItem.checked = false;
			disclaimerItems.push(newItem);
			i += 1;
		});
	});
	return disclaimerItems;
};

export default getDisclaimerItems;

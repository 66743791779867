import { makeStyles, alpha } from '@itsa.io/ui';

// const drawerLeftSpace = 24;
// const drawerWidth = 222 + drawerLeftSpace;

const globalStyles = theme => ({
	body: ({ currentTheme }) => {
		return {
			overflowX: 'hidden', // on mobile, x scroll is annoying
			visibility: 'hidden', // make container div hidden by default
			background: currentTheme.palette.background,
		};
	},
	'html[data-visible="true"] body': {
		// make container div visible, once components/layouts/Layout.jsx adds data-visible="true" to the html element
		visibility: 'visible',
	},
	h1: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h1,
			margin: 0,
		};
	},
	h2: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h2,
			margin: 0,
		};
	},
	h3: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h3,
			margin: 0,
		};
	},
	h4: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h4,
			margin: 0,
		};
	},
	h5: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h5,
			margin: 0,
		};
	},
	h6: ({ currentTheme }) => {
		return {
			...currentTheme.typography.h6,
			margin: 0,
		};
	},
	/* Custom Scrollbar */
	/* scrollbar width */
	'::-webkit-scrollbar': {
		width: '0.51rem',
	},
	/* scrollbar Handle */
	'::-webkit-scrollbar-thumb': {
		WebkitTransition: '0.3s ease all',
		transition: '0.3s ease all',
		backgroundColor: alpha(theme.palette.grey[400], 0.8),
		boxShadow: `inset 0 0 12px ${alpha(theme.palette.common.black, 0.5)}`,
		background: 'transparent',
		borderRadius: 3,
		/* scrollbar Handle on hover */
		'&:hover': {
			backgroundColor: alpha(theme.palette.grey[400], 0.65),
			boxShadow: `inset 0 0 12px ${alpha(theme.palette.common.black, 0.35)}`,
		},
	},
	/* scrollbar Track */
	'::-webkit-scrollbar-track': {
		backgroundColor: alpha(theme.palette.grey[400], 0.8),
		borderRadius: 3,
	},
	/* END Custom Scrollbar */
});

export default makeStyles(theme => ({
	'@global': globalStyles(theme),
	root: ({ currentTheme }) => {
		return {
			display: 'flex',
			minHeight: '100vh',
			color: currentTheme.palette.text.primary,
			paddingTop: 65,
		};
	},
	allItemsHidden: {
		'& *:not([data-showonlocked="true"])': {
			visibility: 'hidden!important',
		},
	},
	backdrop: ({ currentTheme }) => {
		return {
			backgroundColor: `${alpha(
				currentTheme.palette.common.black,
				0.5,
			)}!important`,
			zIndex: 9990,
		};
	},
	developmentLabel: ({ currentTheme }) => {
		return {
			color: currentTheme.palette.text.primary,
			backgroundColor: `${alpha(currentTheme.palette.blue[400], 0.3)}`,
			padding: currentTheme.spacing(0.5, 0.125),
			position: 'fixed',
			top: 66,
			zIndex: 9000,
			right: 0,
			left: 'auto',
			textOrientation: 'upright',
			writingMode: 'vertical-rl',
		};
	},
	wrongNetwork: ({ currentTheme }) => {
		return {
			marginBottom: -56,
			'& .MuiButton-root': {
				color: currentTheme.palette.black,
				backgroundColor: currentTheme.palette.blue[50],
			},
		};
	},
	loginContainer: {
		display: 'flex',
		width: '100%',
		maxWidth: 500,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
	},
	container: ({ currentTheme }) => {
		return {
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',
			alignItems: 'center',
			paddingTop: currentTheme.spacing(8),
			paddingBottom: currentTheme.spacing(10),
			[currentTheme.breakpoints.up('sm')]: {
				paddingTop: currentTheme.spacing(6),
			},
			[currentTheme.breakpoints.up('md')]: {
				paddingTop: currentTheme.spacing(7),
				paddingBottom: currentTheme.spacing(0),
			},
			'& > div:first-child': {
				flexGrow: 1,
			},
		};
	},
}));

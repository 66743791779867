module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false,"disableMinification":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Itsa Wallet","short_name":"wallet","icon":"src/assets/favicon.png","lang":"en","start_url":"/","background_color":"#FFFFFF","theme_color":"#14D84B","display":"minimal-ui","localize":[{"start_url":"/de/","lang":"de"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f6cc3866b0413a3c1789ba348ec4ece2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/itsasbreuk/Documents/SEC-Token-Interface/src/components/layout/Layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/itsasbreuk/Documents/SEC-Token-Interface/src/lang","languages":["en","de"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

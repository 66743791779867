import React from 'react';
import PropTypes from 'prop-types';
import { CombinedProviders } from 'ctx-provider';
import { CryptoWalletProvider } from '@itsa.io/web3utils';
import { Provider as PageProvider } from 'context/page';
import { Provider as DarkModeProvider } from 'context/darkmode';
import { Provider as DisclaimerModalProvider } from 'context/disclaimermodal';
import { Provider as PendingTransactionsProvider } from 'context/pendingtransactions';
import { Provider as BlockheightProvider } from 'context/blockheight';
import { Provider as GasPriceProvider } from 'context/gasprice';
import { Provider as ContactsProvider } from 'context/contacts';
import { Provider as SoundProvider } from 'context/sound';
import { Provider as ExpertModeProvider } from 'context/expertmode';
import { Provider as SlippageProvider } from 'context/slippage';
import { Provider as FeedbackProvider } from 'context/feedback';
import { Provider as TogglemuteProvider } from 'context/togglemute';
import { Provider as LockTimerProvider } from 'context/locktimer';
import { Provider as LastBlockVisibleProvider } from 'context/lastblockvisible';
import { Provider as GasPriceVisibleProvider } from 'context/gaspricevisible';
import { Provider as LatestSystemMessageProvider } from 'context/latestsystemmessage';
import { Provider as ExtraGaspriceHardwareWalletProvider } from 'context/extragaspricehardwarewallet';
import { Provider as NewTokenizedPropertyProvider } from 'context/newtokenizedproperty';

import { NETWORK_NAMES } from 'config';

const GlobalContext = ({ pageProps, children }) => {
	const providers = [
		{
			provider: CryptoWalletProvider,
			args: {
				validChainIds: Object.keys(NETWORK_NAMES).map(chainid =>
					parseInt(chainid, 10),
				),
			},
		},
		{
			provider: PageProvider,
			args: pageProps,
		},
		BlockheightProvider,
		GasPriceProvider,
		ContactsProvider,
		LastBlockVisibleProvider,
		GasPriceVisibleProvider,
		SoundProvider,
		TogglemuteProvider,
		PendingTransactionsProvider,
		LatestSystemMessageProvider,
		DisclaimerModalProvider,
		DarkModeProvider,
		ExpertModeProvider,
		ExtraGaspriceHardwareWalletProvider,
		NewTokenizedPropertyProvider,
		SlippageProvider,
		LockTimerProvider,
		FeedbackProvider,
	];

	return (
		<CombinedProviders providers={providers}>{children}</CombinedProviders>
	);
};

GlobalContext.propTypes = {
	pageProps: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
};

export default GlobalContext;

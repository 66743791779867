import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Alert from 'components/common/Alert';
import useStyles from 'styles/components/common/modals/AlertModal';

const AlertModal = ({ action, classes, handleClose, message, severity }) => {
	const styleClasses = useStyles();

	return (
		<Alert
			action={action}
			className={clsx(styleClasses.alert, classes)}
			severity={severity}
			onClose={handleClose}
		>
			{message}
		</Alert>
	);
};

AlertModal.defaultProps = {
	action: null,
	classes: null,
	handleClose: null,
	severity: 'success',
};

AlertModal.propTypes = {
	action: PropTypes.object,
	classes: PropTypes.string,
	handleClose: PropTypes.func,
	severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
	message: PropTypes.string.isRequired,
};

export default AlertModal;

import { useState } from 'react';
import { cloneDeep } from 'lodash';
import createStore from 'ctx-provider';
import { localStorageProperty } from '@itsa.io/web3utils';

const localStorageNewTokenizedProperty = localStorageProperty(
	'newtokenizedproperty',
	{
		simpleType: false,
		encoded: true,
	},
);

const useStore = () => {
	const [tokenizedProperty, setTokenizedPropertyState] = useState(
		localStorageNewTokenizedProperty.get() || {},
	);

	const setTokenizedProperty = tokenizedProperty => {
		tokenizedProperty = cloneDeep(tokenizedProperty);
		setTokenizedPropertyState(tokenizedProperty);
		localStorageNewTokenizedProperty.set(tokenizedProperty);
	};

	const cancelTokenizedProperty = () => {
		localStorageNewTokenizedProperty.remove();
		setTokenizedPropertyState({});
	};

	return {
		tokenizedProperty,
		setTokenizedProperty,
		cancelTokenizedProperty,
	};
};

const store = createStore(useStore);

export const { Provider } = store;
export default store.ctx;
